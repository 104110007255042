@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Poppins:wght@400;700&display=swap');

:root {
  --gradient-bg-start: #0f1219;
  --gradient-bg-end: #1a202c;
  --gradient-text-1: #f0fdfa;
  --gradient-text-2: #99f6e4;
  --gradient-text-3: #3ebdb2;
  --gradient-text-4: #53dacf;
}

body {
  background: linear-gradient(135deg, var(--gradient-bg-start) 0%, var(--gradient-bg-end) 100%);
  min-height: 100vh;
  margin: 0;
  font-family: 'Inter', 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.gradient-text {
  background: linear-gradient(
    45deg,
    #bcc5d0 0%,
    #e2e8f0 50%,
    #f8fafc 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.gradient-text-2 {
  background: linear-gradient(
    135deg,
    var(--gradient-text-1) 0%,
    var(--gradient-text-2) 25%,
    var(--gradient-text-3) 75%,
    var(--gradient-text-1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  background-size: 300% 300%;
  animation: gradient-shift 8s ease infinite;
}

.gradient-text-3 {
  background: linear-gradient(
    135deg,
    var(--gradient-text-1) 0%,
    var(--gradient-text-2) 25%,
    var(--gradient-text-4) 75%,
    var(--gradient-text-1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  background-size: 300% 300%;
  animation: gradient-shift 5s ease infinite;
}

@keyframes gradient-shift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.sub-text {
  transition: all 0.5s ease;
  opacity: 0.9;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  letter-spacing: -0.025em;
}

p {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.hover-glow {
  transition: all 0.3s ease;
}

.hover-glow:hover {
  box-shadow: 0 0 15px rgba(153, 246, 228, 0.5);
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}